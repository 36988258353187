import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps } from "formik";
import { useAppDispatch } from "../../hooks/storeHooks";
import XGSFormTextarea from "../../components/form/textarea/xgsFormTextarea";
import Button, { ButtonThemes } from "../../components/button/button";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import {
  ExceptionModel,
  ExceptionFormModel,
  ExceptionSchema,
  ExceptionIncidentOptions,
} from "../../app/data/exceptions/models";
// import { RouteSummaryModel } from "../../app/data/route-summary/models";
// import {
//   ConsigneeDetails,
//   ProbillDetails,
//   ProbillItem,
//   StopDetails
// } from "../../app/data/stop-details/models";
// import { updateStopsStatuses } from "../../app/common/statusUpdater";
import {
  exceptionsSelector,
  resetExceptionsState,
  submitException,
} from "../../slices/exceptions/exceptionsSlice";
import ExceptionsState from "../../slices/exceptions/ExceptionsState";
import { ACCEPTED_FORMATS, MAX_PHOTOS, MAX_SIZE } from "./constants";
import "./exceptions.scss";
import LabeledSelectInput from "../../components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../components/molecules/labeled-inputs/labeledInput";

let initialValues: ExceptionFormModel = {
  reason: "",
  comment: "",
};

interface AddExceptionModalProps {
  source: string;
  probills: number[];
  itemId: string | null;
  onAddException: () => void;
  exception: ExceptionModel | null;
  stopNumber: number;
}

const AddExceptionModal: React.FC<AddExceptionModalProps> = ({ source, exception, ...props }) => {
  const dispatch = useAppDispatch();
  const exceptionFormRef = useRef<FormikProps<ExceptionFormModel>>(null);
  const exceptionsState: ExceptionsState = useSelector(exceptionsSelector);
  const [photos, setPhotos] = useState<any>([]);
  const [fileError, setFileError] = useState<string>("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: ACCEPTED_FORMATS,
    maxSize: MAX_SIZE * 1048576,
    maxFiles: MAX_PHOTOS - photos.filter((obj: any) => !obj.deleted).length,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setFileError(`Files no larger than ${MAX_SIZE} MB are allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setFileError(
              "Only images of certain formats (JPEG, PNG, WebP, GIF and BMP) are allowed!"
            );
          }
          if (err.code === "too-many-files") {
            setFileError(`Maximum ${MAX_PHOTOS} photos are allowed!`);
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setPhotos([
        ...photos,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            deleted: false,
            comment: "",
            internalOnly: false,
          })
        ),
      ]);
    },
  });

  const photosBlock = photos.map((photo: any, i: number) => (
    <div
      className={`xgs-exceptions__form__photos__item ${
        photo.deleted ? "xgs-exceptions__form__photos__item--deleted" : ""
      }`}
      key={`photo.name-${i}`}
    >
      <div className="xgs-exceptions__form__photos__item__image">
        <img src={photo.preview} alt="Preview" />
        <div
          className="xgs-exceptions__form__photos__item__image__control"
          onClick={() => removePhoto(i)}
        >
          <XGSIcon icon={XGSIcons.faTimes} className="xgs-exceptions__form__photos__item__icon" />
        </div>
      </div>
    </div>
  ));

  const removePhoto = (i: number) => {
    let newArr = [...photos];
    newArr[i].deleted = true;
    setPhotos(newArr);
  };
  
  const onSubmitException = (data: ExceptionFormModel) => {
    if (exception) return;
    const actualPhotos = photos.filter((obj: any) => !obj.deleted);
    const fd = new FormData();
    for (let photo of actualPhotos) {
      fd.append("files", photo);
    }
    const preparedData = {
      comment: data.comment || "",
      ...(props.itemId && { itemIdentifier: props.itemId }),
      level: source,
      probills: props.probills,
      reason: data.reason,
      stop: props.stopNumber,
    };
    fd.append("data", JSON.stringify(preparedData));
    dispatch(
      submitException(
        fd,
        () => {
          toast.info("The exception has been submitted successfully!");
          props.onAddException();
        },
        () => {
          toast.info(
            "The exception has been queued to be submitted when the Driver App is online!"
          );
          props.onAddException();
        }
      )
    );
  };

  const isPhotoRequired = () => {
    return exceptionFormRef.current?.values.reason === "ITEM_DAMAGED";
  };

  useEffect(() => {
    dispatch(resetExceptionsState());
    exceptionFormRef.current?.setValues(initialValues);
    if (exception) {
      exceptionFormRef.current?.setFieldValue("reason", exception.reason);
      exceptionFormRef.current?.setFieldValue("refused", exception.refused);
      exceptionFormRef.current?.setFieldValue("comment", exception.comment);
    }

    return () => {
      dispatch(resetExceptionsState());
    };
  }, [exception, dispatch]);

  return (
    <div className="xgs-exceptions">
      <Formik
        innerRef={exceptionFormRef}
        onSubmit={onSubmitException}
        initialValues={initialValues}
        validationSchema={ExceptionSchema}
        enableReinitialize
      >
        {(props: FormikProps<ExceptionFormModel>) => (
          <Form className="xgs-exceptions__form">
            {!exception && (
              <LabeledSelectInput
                label="Reason:"
                required
                options={ExceptionIncidentOptions}
                name="reason"
                labelMode={LabelModes.column}
                disabled={!!exception}
                onValueChange={(val) => props.setFieldValue("reason", val?.value)}
              />
            )}
            {exception && (
              <p style={{ marginBottom: 16 }}>
                Reason: <b>{exception.reason}</b>
              </p>
            )}
            {!exception && (
              <div className="xgs-exceptions__form__upload">
                <div className="xgs-form__label">
                  Photos: {isPhotoRequired() ? <span>*</span> : ""}
                </div>
                {!exception && (
                  <>
                    {photos.filter((obj: any) => !obj.deleted).length < MAX_PHOTOS && (
                      <div {...getRootProps({ className: "xgs-upload__area" })}>
                        <input {...getInputProps()} />
                        <XGSIcon icon={XGSIcons.faCamera} className="xgs-upload__area__icon" />
                        <span className="blue-link">Tap to take or attach a photo</span>
                        {fileError && <span className="xgs-upload__area__error">{fileError}</span>}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            {photos.length > 0 && <div className="xgs-exceptions__form__photos">{photosBlock}</div>}
            <div>
              <XGSFormTextarea
                name="comment"
                label="Comments:"
                required
                rows={3}
                counter={60}
                className="xgs-exceptions__form__textarea"
                disabled={!!exception}
              />
            </div>
            {exceptionsState.requestFailed && (
              <XGSErrorMessage className="xgs-exceptions__form__error">
                {exceptionsState.requestErrorCode === 401 ||
                exceptionsState.requestErrorCode === 403
                  ? "The session has expired! Please refresh the page and try again."
                  : exceptionsState.requestError || "Error"}
              </XGSErrorMessage>
            )}
            {!exception && (
              <div className="xgs-exceptions__form__buttons">
                <Button
                  theme={ButtonThemes.blue}
                  type="submit"
                  className="xgs-exceptions__form__submit"
                  spinner={
                    exceptionsState.requestStarted &&
                    exceptionsState.requestCreator === "SUBMIT_EXCEPTION"
                  }
                  // disabled={!props.isValid || !props.dirty || (photos.filter((obj: any) => !obj.deleted).length === 0 && isPhotoRequired())}
                >
                  Submit
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddExceptionModal;
