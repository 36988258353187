import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../slices/user/userSlice";
import UserState from "../../slices/user/UserState";
import logo from "./../../images/logo.svg";
import { publicRoutesComponents } from "../../app/route/RoutesConfig";
import UserMenu from "./user-menu/userMenu";
import OfflineNotifier from "./offline-notifier/offlineNotifier";
import "./header.scss";

const Header: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const location = useLocation();

  const isPublicPage = () => {
    return publicRoutesComponents.find((route) => {
      return route.fixedPath && location.pathname.startsWith(route.fixedPath + '/');
    });
  };

  return (
    <div className="xgs-header">
      <div className="xgs-header__start">
        <OfflineNotifier />
      </div>
      <div className="xgs-header__middle">
        <div className="xgs-header__logo">
          <NavLink to="/">
            <img className="xgs-logo" src={logo} alt="xgsi.com logo" />
          </NavLink>
        </div>
      </div>
      <div className="xgs-header__end">
        {userState?.loggedIn && !isPublicPage() && <UserMenu />}
      </div>
    </div>
  );
};

export default Header;
