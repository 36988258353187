import React, { useEffect, useMemo, useRef, useState } from "react";
import Button, { ButtonThemes } from "../../../components/button/button";
import ReactSignatureCanvas from "react-signature-canvas";
import "./signShipment.scss";
import LabeledInput, {
  LabelModes,
} from "../../../components/molecules/labeled-inputs/labeledInput";
import { Formik, FormikProps, Form } from "formik";
import {
  SubmitPodRequest,
  SubmitPodModelRequestSchema,
  ConsigneeDetails,
} from "../../../app/data/stop-details/models";
import XGSFormInput from "../../../components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../components/form/textarea/xgsFormTextarea";
import { useAppDispatch } from "../../../hooks/storeHooks";
import {
  deliverShipmentSelector,
  submitPod,
} from "../../../slices/stop-details/deliverShipmentSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../app/route/RoutesConfig";
import { StopDetailsPath } from "../routes";
import { getRouteSummary } from "../../../slices/route-summary/routeSummarySlice";
import { getRouteMapData } from "../../../slices/route-map/routeMapSlice";
import { getAllStopsDetails } from "../../../slices/stop-details/stopDetailsSlice";
import debounce from "lodash/debounce";
import { updateCacheAfterDelivery } from "../../../app/common/statusUpdater";

interface Props {
  show: boolean;
  consignee: ConsigneeDetails;
  onCloseModal: () => void;
}

export const SignShipment: React.FC<Props> = ({ show, consignee, onCloseModal }) => {
  const padRef = useRef<ReactSignatureCanvas | null>(null);
  const formRef = useRef<FormikProps<SubmitPodRequest>>(null);
  const [showCanvas, setShowCanvas] = useState<boolean>(false);
  const [signatureTouched, setSignatureTouched] = useState(false);
  const [signatureError, setSignatureError] = useState("");
  const deliverShipmentState = useSelector(deliverShipmentSelector);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const stopNumber = useMemo(() => {
    const stopParam = params[StopDetailsPath.stopNumber];
    if (stopParam) return parseInt(stopParam, 10);
    if (!stopParam) navigate(ROUTES.home);
  }, [params, navigate]) as number;

  const initialFormValues: SubmitPodRequest = {
    signer: "",
    signature: "",
    notes: "",
  };

  useEffect(() => {
    // Canvas does not render properly without delaying
    const timeout = setTimeout(() => {
      setShowCanvas(true);
    }, 500);
    return () => {
      clearTimeout(timeout);
      setShowCanvas(false);
    };
  }, []);

  const onClear = () => {
    padRef.current?.clear();
    formRef.current?.setValues(initialFormValues);
    setSignatureTouched(false);
    validateSignature();
  };

  const isSignatureValid = () => {
    const data = padRef.current?.getTrimmedCanvas().toDataURL();
    if (!data || data?.length < 5000 || data?.length > 40000) {
      return false;
    }
    return true;
  };

  const validateSignature = () => {
    if (!isSignatureValid()) {
      setSignatureError("Signature is required");
    } else {
      setSignatureError("");
    }
  };

  const onSubmit = (values: SubmitPodRequest) => {
    const data = padRef.current?.getTrimmedCanvas().toDataURL();
    if (!isSignatureValid()) return setSignatureError("Signature is required");
    if (data) {
      values = { ...values, signature: data };
    }
    values.probills = consignee.probills
      .filter(
        (probill) =>
          !probill.exception?.refused &&
          !probill.itemDetails.every((item) => item.exception?.refused)
      )
      .map((probill) => probill.probill);
    values.stop = stopNumber;
    setSignatureError("");
    dispatch(
      submitPod(
        values,
        (response) => {
          toast.info(
            navigator.onLine
              ? "The POD has been saved!"
              : "The POD will be saved when Internet connection is restored!"
          );
          if (!navigator.onLine) {
            // updateCache();
            updateCacheAfterDelivery(stopNumber, consignee.name);
            if (consignee.probills.length === 1) navigate(ROUTES.home);
            dispatch(getRouteSummary());
            dispatch(getAllStopsDetails());
            dispatch(getRouteMapData());
            onCloseModal();
          } else {
            if (consignee.probills.length === 1) navigate(ROUTES.home);
            dispatch(getRouteSummary());
            dispatch(getAllStopsDetails());
            dispatch(getRouteMapData());
            onCloseModal();
          }
        },
        () => {
          toast.error("Something went wrong");
        }
      )
    );
  };

  return (
    <div className="xgs-sign-shipment">
      <Formik
        innerRef={formRef}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={SubmitPodModelRequestSchema}
      >
        {(props: FormikProps<SubmitPodRequest>) => (
          <Form>
            <LabeledInput
              required
              error={signatureError}
              label="Signature:"
              labelMode={LabelModes.column}
            >
              <div
                className={`xgs-sign-shipment__sign-pad ${
                  signatureError ? "xgs-sign-shipment__sign-pad--error" : ""
                }`}
              >
                {!signatureTouched && (
                  <div className="xgs-sign-shipment__sign-pad__placeholder">Sign here</div>
                )}
                {showCanvas && (
                  <ReactSignatureCanvas
                    clearOnResize={false}
                    onBegin={() => setSignatureTouched(true)}
                    onEnd={debounce(validateSignature, 800)}
                    canvasProps={{
                      className: "xgs-sign-shipment__sign-pad__canvas",
                    }}
                    ref={(canvasRef) => (padRef.current = canvasRef)}
                  />
                )}
              </div>
            </LabeledInput>
            {signatureError && (
              <div className="xgs-labeled-input__validation-error">{signatureError}</div>
            )}
            <XGSFormInput
              required
              maxLength={40}
              onChange={(e) => props.setFieldValue("signer", e.currentTarget.value)}
              name="signer"
              label="Printed Name:"
              className="xgs-sign-shipment__name"
              labelMode={LabelModes.column}
            />
            <XGSFormTextarea
              counter={200}
              maxLength={200}
              name="notes"
              className="xgs-sign-shipment__notes"
              label="Notes:"
            />
            <div className="xgs-sign-shipment__buttons">
              <Button
                onClick={() => {
                  validateSignature();
                  props.submitForm();
                }}
                spinner={deliverShipmentState.requestStarted}
                type="button"
                theme={ButtonThemes.blue}
                disabled={!!props.errors.signer || !!signatureError}
              >
                Submit
              </Button>
              <Button type="button" onClick={onClear} theme={ButtonThemes.gray}>
                Clear
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
