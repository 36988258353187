import sortBy from "lodash/sortBy";
import { RouteSummaryModel, RouteSummaryStopModel } from "../data/route-summary/models";
import {
  ConsigneeDetails,
  ProbillDetails,
  ProbillItem,
  StopDetails,
} from "../data/stop-details/models";
import { MapMarkerModel, RouteMapModel } from "../data/route-map/models";
import { StopStatus } from "../data/common/constants";

export const updateCacheAfterDelivery = async (stopNumber: number, consigneeName: string) => {
  const cache = await caches.open("xgs-driver-app-api-responses");
  const cachedStopsResponse = await cache.match(
    process.env.REACT_APP_API_BASE_URL + "/drivers/routes/agents/stops"
  );
  let stopsResponseData: StopDetails[];
  if (cachedStopsResponse) {
    stopsResponseData = JSON.parse(await cachedStopsResponse.text());
  } else return;

  const stopIndex = stopsResponseData.findIndex((stop: StopDetails) => stop.order === stopNumber);
  const stop = stopsResponseData[stopIndex];

  let newStops;
  if (stop.consignees.length === 1) {
    newStops = stopsResponseData.filter((stop, idx) => idx !== stopNumber);
    console.log("new", newStops)
  } else {
    const newConsignees = stop.consignees.filter((consignee) => consignee.name !== consigneeName);
    newStops = [...stopsResponseData];
    newStops[stopIndex].consignees = newConsignees;
  }

  await cache.put(
    process.env.REACT_APP_API_BASE_URL + "/drivers/routes/agents/stops",
    new Response(JSON.stringify(stopsResponseData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
  );
};

export const updateCacheForCompletedPickup = async (stopNumber: number) => {
  const cache = await caches.open("xgs-driver-app-api-responses");

  const cachedStopsResponse = await cache.match(
    process.env.REACT_APP_API_BASE_URL + "/drivers/routes/agents/stops"
  );
  let stopsResponseData: StopDetails[];
  if (cachedStopsResponse) {
    stopsResponseData = JSON.parse(await cachedStopsResponse.text());
  } else return;

  const stopIndex = stopsResponseData.findIndex((stop: StopDetails) => stop.order === stopNumber);
  stopsResponseData[stopIndex].status = StopStatus.COMPLETED;

  const cachedRouteResponse = await cache.match(
    process.env.REACT_APP_API_BASE_URL + "/drivers/routes/agents"
  );
  let routeResponseData: RouteSummaryModel;
  if (cachedRouteResponse) {
    routeResponseData = JSON.parse(await cachedRouteResponse.text());
  } else return;
  const routeStopIndex = routeResponseData.stops.findIndex(
    (stop: RouteSummaryStopModel) => stop.order === stopNumber
  );
  routeResponseData.stops[routeStopIndex].status = StopStatus.COMPLETED;

  await cache.put(
    process.env.REACT_APP_API_BASE_URL + "/drivers/routes/agents",
    new Response(JSON.stringify(routeResponseData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
  );

  await cache.put(
    process.env.REACT_APP_API_BASE_URL + "/drivers/routes/agents/stops",
    new Response(JSON.stringify(stopsResponseData), {
      headers: {
        "Content-Type": "application/json",
      },
    })
  );
};

export const updateStopsStatuses = async (stopNumber: number) => {
  const cache = await caches.open("xgs-driver-app-api-responses");

  const cachedStopsResponse = await cache.match(
    process.env.REACT_APP_API_BASE_URL + "/drivers/routes/agents/stops"
  );
  let stopsResponseData: StopDetails[];
  if (cachedStopsResponse) {
    stopsResponseData = JSON.parse(await cachedStopsResponse.text());
  } else return;

  const stopIndex = stopsResponseData.findIndex((stop: StopDetails) => stop.order === stopNumber);

  const stopUncompleted =
    stopsResponseData[stopIndex].consignees.filter(
      (consignee: ConsigneeDetails) =>
        !consignee.delivered &&
        !(consignee.exception && consignee.exception.refused) &&
        consignee.probills.filter(
          (probillObj: ProbillDetails) =>
            !(probillObj.exception && probillObj.exception.refused) &&
            probillObj.itemDetails.filter(
              (item: ProbillItem) => !(item.exception && item.exception.refused)
            ).length > 0
        ).length > 0
    ).length > 0;

  if (!stopUncompleted) {
    const cachedRouteResponse = await cache.match(
      process.env.REACT_APP_API_BASE_URL + "/drivers/routes/agents"
    );
    let routeResponseData: RouteSummaryModel;
    if (cachedRouteResponse) {
      routeResponseData = JSON.parse(await cachedRouteResponse.text());
    } else return;
    const routeStopIndex = routeResponseData.stops.findIndex(
      (stop: RouteSummaryStopModel) => stop.order === stopNumber
    );
    routeResponseData.stops[routeStopIndex].status = StopStatus.COMPLETED;

    stopsResponseData[stopIndex].status = StopStatus.COMPLETED;

    const cachedMapResponse = await cache.match(
      process.env.REACT_APP_API_BASE_URL + "/drivers/routes/map"
    );
    let mapResponseData;

    if (cachedMapResponse) {
      mapResponseData = JSON.parse(await cachedMapResponse.text()) as RouteMapModel;
      const markerIndex = mapResponseData.markers.findIndex(
        (marker: MapMarkerModel) => Number(marker.label) === stopNumber
      );
      if (markerIndex !== -1) {
        mapResponseData.markers[markerIndex].status = StopStatus.COMPLETED;
      }
    }

    // check if we dont have another Active stop already
    if (
      stopsResponseData.findIndex((stop: StopDetails) => stop.status === StopStatus.ACTIVE) === -1
    ) {
      let availableStops = stopsResponseData.filter(
        (stop: StopDetails) => stop.status === StopStatus.UPCOMING
      );
      if (availableStops.length > 0) {
        sortBy(availableStops, ["order"]);
        const nextStopOrder = availableStops[0].order;
        if (nextStopOrder) {
          const nextStopIndex = stopsResponseData.findIndex(
            (stop: StopDetails) => stop.order === nextStopOrder
          );
          if (nextStopIndex !== -1) stopsResponseData[nextStopIndex].status = StopStatus.ACTIVE;

          const nextRouteStopIndex = routeResponseData.stops.findIndex(
            (stop: RouteSummaryStopModel) => stop.order === nextStopOrder
          );
          if (nextRouteStopIndex !== -1)
            routeResponseData.stops[nextRouteStopIndex].status = StopStatus.ACTIVE;

          if (mapResponseData) {
            const nextMarkerIndex = mapResponseData.markers.findIndex(
              (marker: MapMarkerModel) => Number(marker.label) === nextStopIndex + 1
            );
            mapResponseData.markers[nextMarkerIndex].status = StopStatus.ACTIVE;
          }
        }
      }
    }

    await cache.put(
      process.env.REACT_APP_API_BASE_URL + "/drivers/routes/agents",
      new Response(JSON.stringify(routeResponseData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );

    await cache.put(
      process.env.REACT_APP_API_BASE_URL + "/drivers/routes/agents/stops",
      new Response(JSON.stringify(stopsResponseData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );

    await cache.put(
      process.env.REACT_APP_API_BASE_URL + "/drivers/routes/map",
      new Response(JSON.stringify(mapResponseData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
  }
};
