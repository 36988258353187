import api, { ApiResponse } from "../api";
import { RouteMapModel } from "./models";

class RouteMapService {
  private static instance: RouteMapService;
  private constructor() {}
  static getInstance(): RouteMapService {
    if (!RouteMapService.instance) {
      RouteMapService.instance = new RouteMapService();
    }
    return RouteMapService.instance;
  }

  public getRouteMapData = async (): Promise<ApiResponse<RouteMapModel>> => {
    return await api.get("/drivers/routes/agents/map");
  };
}

export default RouteMapService;