import React from "react";
import { useSelector } from "react-redux";
import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";
import "./routeSummary.scss";

const NoRoute: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);

  return (
    <div className="xgs-route-summary__no-route">
      <div className="xgs-route-summary__no-route__header">
        {userState.profile?.firstName ? `${userState.profile.firstName}, you ` : "You "} have not been assigned a route.
      </div>
      <div className="xgs-route-summary__no-route__contact">
        <div className="xgs-route-summary__no-route__contact__header">
          Please contact your dispatch office.
        </div>
      </div>
    </div>
  );
}

export default NoRoute;
