import api, { ApiResponse } from "../api";

class RouteSummaryService {
  private static instance: RouteSummaryService;
  private constructor() {};

  static getInstance(): RouteSummaryService {
    if (!RouteSummaryService.instance) {
      RouteSummaryService.instance = new RouteSummaryService();
    }
    return RouteSummaryService.instance;
  };

  public getRouteSummary = async (): Promise<ApiResponse<any>> => {
    return await api.get("/drivers/routes/agents");
  };
}

export default RouteSummaryService;
