import { createSlice } from "@reduxjs/toolkit";
import { initialDeliverShipmentState } from "./deliverShipmentState";
import {
  SubmitPodRequest,
  SubmitPodResponse,
} from "../../app/data/stop-details/models";
import { AppThunk } from "../../app/store";
import { IState } from "..";
import DeliverShipmentsService from "../../app/data/stop-details/deliverShipmentsService";
import { StopStatus } from "../../app/data/common/constants";

export const deliverShipmentSelector = (state: IState) => {
  return state.deliverShipment;
};

const deliverShipmentsService = DeliverShipmentsService.getInstance();

export const deliverShipmentSlice = createSlice({
  name: "deliverShipment",
  initialState: initialDeliverShipmentState,
  reducers: {
    resetDeliveryShipment: (state) => {
      return initialDeliverShipmentState;
    },
    startSubmitPodRequest: (state) => {
      state.requester = "SUBMIT_POD";
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
    },
    finishSubmitPodRequest: (state) => {
      state.requestSucceed = true;
      state.requestStarted = false;
      state.requestFailed = false;
    },
    submitPodRequestFailed: (state, { payload }) => {
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailReason = payload;
      state.requestStarted = false;
    },
  },
});

export const {
  resetDeliveryShipment,
  startSubmitPodRequest,
  finishSubmitPodRequest,
  submitPodRequestFailed,
} = deliverShipmentSlice.actions;


export const submitPod = (
  request: SubmitPodRequest,
  onSuccess: (response: SubmitPodResponse) => void,
  onFailed: (error?: string) => void
): AppThunk => async (dispatch) => {
  dispatch(startSubmitPodRequest());
  const response = await deliverShipmentsService.submitPod(request);
  if (response.ok()) {
    dispatch(finishSubmitPodRequest());
    onSuccess(response.data);
  } else {
    if (navigator.onLine) {
      dispatch(submitPodRequestFailed(response.getError && response.getError()));
      onFailed(response.getError && response.getError()?.toString())
    } else {
      dispatch(finishSubmitPodRequest());
      onSuccess({
        stop: request.stop || 0,
        status: StopStatus.COMPLETED
      });
    }
  }
};

export const deliverShipmentReducer = deliverShipmentSlice.reducer;
