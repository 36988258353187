import React, { useState } from "react";
import { ExceptionModel } from "../../app/data/exceptions/models";
import ExceptionModal from "./exceptionModal";
import { useAppDispatch } from "../../hooks/storeHooks";
import SlideInModal from "../../components/slide-in-modal/slideInModal";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import { getRouteSummary } from "../../slices/route-summary/routeSummarySlice";
import { getRouteMapData } from "../../slices/route-map/routeMapSlice";
import { getAllStopsDetails } from "../../slices/stop-details/stopDetailsSlice";
import "./exceptions.scss";

interface ExceptionButtonProps {
  stopNumber: number;
  probills: number[];
  itemId: string | null;
  source: string;
  exception: ExceptionModel | null;
};

const ExceptionButton: React.FC<ExceptionButtonProps> = ({ stopNumber, probills, itemId, source, exception }) => {
  const dispatch = useAppDispatch();
  const [showAddException, setShowAddException] = useState<boolean>(false);

  return (
    <>
      <div
        className={`xgs-exceptions__button${exception ? " xgs-exceptions__button--existed" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          setShowAddException(true)
        }}
      >
        <div>
          <XGSIcon
            icon={exception ? XGSIcons.faEye : XGSIcons.faPlus}
            className="xgs-exceptions__button__icon"
          />
        </div>
        <div className="xgs-exceptions__button__text">Exception</div>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <SlideInModal
          title={`${!exception ? "Add exception" : "Exception"} on ${probills.length > 1 ? "consignee" : probills[0] + (itemId ? " - " + itemId : "")}`}
          show={showAddException}
          onClose={() => setShowAddException(false)}
        >
          {showAddException &&
            <ExceptionModal
              probills={probills}
              itemId={itemId}
              onAddException={() => {
                setShowAddException(false);
                dispatch(getRouteSummary());
                dispatch(getAllStopsDetails());
                dispatch(getRouteMapData());
              }}
              source={source}
              exception={exception}
              stopNumber={stopNumber}
            />
          }
        </SlideInModal>
      </div>
    </>
  );
}

export default ExceptionButton;
