import api, { ApiResponse } from "../api";

class StopDetailsService {
  private static instance: StopDetailsService;
  private constructor() {};

  static getInstance(): StopDetailsService {
    if (!StopDetailsService.instance) {
      StopDetailsService.instance = new StopDetailsService();
    }
    return StopDetailsService.instance;
  };

  public getAllStopsDetails = async (): Promise<ApiResponse<any>> => {
    return await api.get("/drivers/routes/agents/stops");
  };
}

export default StopDetailsService;
