import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import RouteSummaryState from "../../slices/route-summary/RouteSummaryState";
import { routeSummarySelector } from "../../slices/route-summary/routeSummarySlice";
import { ProbillModel, RouteSummaryStopModel } from "../../app/data/route-summary/models"
import { StopStatus } from "../../app/data/common/constants";
import NoRoute from "./noRoute";
import AddressMapButton from "./addressMapButton";
import DataLoader from "../data-loader/dataLoader";
import "./routeSummary.scss";

const RouteSummary: React.FC<{}> = (props) => {
  const routeSummaryState: RouteSummaryState = useSelector(routeSummarySelector);
  const navigate = useNavigate();

  const getStopItems = (probills: ProbillModel[]) => {
    return probills.map((probill: ProbillModel) => probill.items).reduce((total: number, current: number) => total + current);
  };

  const getStopWeight = (probills: ProbillModel[]) => {
    const stopTotalWeight = probills.map((probill: ProbillModel) => probill.weight).reduce((total: number, current: number) => total + current);
    return parseFloat(stopTotalWeight.toFixed(2));
  };

  const isFirstPickup = (currentIndex: number, stops: RouteSummaryStopModel[]) => {
    if (stops.findIndex(stop => stop.type === "PICKUP") === currentIndex) return true
  }

  const hasActivePickup = useMemo(() => routeSummaryState.route?.stops?.some(stop => stop.status !== "COMPLETED" && stop.type === "PICKUP"), [routeSummaryState]);

  return (
    <div className="xgs-route-summary">
      {!routeSummaryState.route && <DataLoader />}
      {routeSummaryState.requestSucceed && routeSummaryState.requester === "SUMMARY" && routeSummaryState.route && (
        <>
          {!!routeSummaryState.route.stops?.length && (
            <>
              {/* <div className="xgs-route-summary__numbers">
                <div className="xgs-route-summary__numbers__item">
                  <div className="xgs-route-summary__numbers__item__value">{routeSummaryState.route.manifestNumber}</div>
                  Manifest #
                </div>
                <div className="xgs-route-summary__numbers__item">
                  <div className="xgs-route-summary__numbers__item__value">{routeSummaryState.route.trailerNumber}</div>
                  Trailer #
                </div>
                <div className="xgs-route-summary__numbers__item">
                  <div className="xgs-route-summary__numbers__item__value">{routeSummaryState.route.tractorNumber}</div>
                  Tractor #
                </div>
              </div> */}
              <div className="xgs-route-summary__totals">
                {!!routeSummaryState.route.stops && (
                  <div className="xgs-route-summary__totals__item">
                    <div className="xgs-route-summary__totals__item__icon">
                      <XGSIcon
                        icon={XGSIcons.faMapMarkerAlt}
                      />
                    </div>
                    <div className="xgs-route-summary__totals__item__text">
                      <span>{routeSummaryState.route.stops.length}</span><br/> Stop{routeSummaryState.route.stops.length > 1 ? "s" : ""}
                    </div>
                  </div>
                )}
                {!!routeSummaryState.route.routeLength && (
                  <div className="xgs-route-summary__totals__item">
                    <div className="xgs-route-summary__totals__item__icon">
                      <XGSIcon
                        icon={XGSIcons.faRuler}
                      />
                    </div>
                    <div className="xgs-route-summary__totals__item__text">
                      <span>{parseFloat(routeSummaryState.route.routeLength.toFixed(2))}</span><br/> Mile{parseFloat(routeSummaryState.route.routeLength.toFixed(2)) !== 1 ? "s" : ""}
                    </div>
                  </div>
                )}
                {!!routeSummaryState.route.numberOfItems && (
                  <div className="xgs-route-summary__totals__item">
                    <div className="xgs-route-summary__totals__item__icon">
                      <XGSIcon
                        icon={XGSIcons.faBoxes}
                      />
                    </div>
                    <div className="xgs-route-summary__totals__item__text">
                      <span>{routeSummaryState.route.numberOfItems}</span><br/> Item{routeSummaryState.route.numberOfItems > 1 ? "s" : ""}
                    </div>
                  </div>
                )}
                {!!routeSummaryState.route.weight && (
                  <div className="xgs-route-summary__totals__item">
                    <div className="xgs-route-summary__totals__item__icon">
                      <XGSIcon
                        icon={XGSIcons.faWeight}
                      />
                    </div>
                    <div className="xgs-route-summary__totals__item__text">
                      <span>{parseFloat(routeSummaryState.route.weight.toFixed(2))}</span><br/> Pound{parseFloat(routeSummaryState.route.weight.toFixed(2)) !== 1 ? "s" : ""}
                    </div>
                  </div>
                )}
              </div>
              {hasActivePickup && <div className="xgs-route-summary__pickup-message">You have pickups, scroll down to see them</div>}
              <div className="xgs-route-summary__list">
                {routeSummaryState.route.origin && (
                  <div className="xgs-route-summary__list__item xgs-route-summary__list__item--sc xgs-route-summary__list__item--sc-origin">
                    <div className="xgs-route-summary__list__item__box">
                      <div className="xgs-route-summary__list__item__info">
                        <div className="xgs-route-summary__list__item__number">
                          <XGSIcon
                            icon={XGSIcons.faWarehouse}
                            className="xgs-route-summary__list__item__icon"
                          />
                        </div>
                        <div className="xgs-route-summary__list__item__details">
                          <div className="xgs-route-summary__list__item__details__address">
                            {routeSummaryState.route.origin.address.address1}, {routeSummaryState.route.origin.address.city}, {routeSummaryState.route.origin.address.state}
                          </div>
                          <div className="xgs-route-summary__list__item__details__info">XGS Service Center</div>
                        </div>
                      </div>
                      <div className="xgs-route-summary__list__item__right-column">
                        <AddressMapButton
                          lat={routeSummaryState.route.origin.address.lat}
                          lng={routeSummaryState.route.origin.address.lng}
                        />
                      </div>
                    </div>
                    {routeSummaryState.route.stops && (
                      <div className="xgs-route-summary__list__item__sc-arrow"></div>
                    )}
                  </div>
                )}
                {routeSummaryState.route.stops && (
                  <>
                    {routeSummaryState.route.stops.map((stop, index) => (
                      <>
                        {isFirstPickup(index, routeSummaryState.route?.stops!) && <div className="xgs-route-summary__list__pickup-heading"><span>PICKUPS</span></div>}
                        <div
                          className={`
                            xgs-route-summary__list__item
                            ${stop.status === StopStatus.COMPLETED ? " xgs-route-summary__list__item--past" : ""}
                            ${stop.status === StopStatus.ACTIVE ? " xgs-route-summary__list__item--active" : ""}
                          `}
                          key={"item-" + index}
                        >
                          <div
                            className="xgs-route-summary__list__item__box"
                            onClick={() => navigate(`/stop-details/${stop.order}`)}
                          >
                            <div className="xgs-route-summary__list__item__info">
                              <div className="xgs-route-summary__list__item__number">
                                {(stop.type === "DELIVERY" || stop.type === "PICKUP") && <>{index + 1}</>}
                              </div>
                              <div className="xgs-route-summary__list__item__details">
                                <div className="xgs-route-summary__list__item__details__address">
                                  {stop.address.address1}, {stop.address.city}, {stop.address.state}
                                </div>
                                {(stop.type === "DELIVERY" || stop.type === "PICKUP") && (
                                  <div className="xgs-route-summary__list__item__details__info">
                                    {stop.probills.length} Probill{stop.probills.length > 1 ? "s" : ""},&nbsp;
                                    {getStopItems(stop.probills)} Item{getStopItems(stop.probills) > 1 ? "s" : ""},&nbsp;
                                    {getStopWeight(stop.probills)} Pound{getStopWeight(stop.probills) !== 1 ? "s" : ""}
                                    {stop.exceptions && stop.exceptions.length > 0 && (
                                      <>
                                        ,&nbsp;<span className="xgs-route-summary__list__item__details__info__exceptions">{stop.exceptions.length} Exception{stop.exceptions.length > 1 ? "s" : ""}</span>
                                      </>
                                    )}
                                  </div>
                                )}
                                {stop.type === "PICKUP" && (
                                  <div className="xgs-route-summary__list__item__time">
                                    {/* <XGSIcon icon={XGSIcons.faClock}/>  */}
                                    {stop.pickupReadyTime?.toUiTimeFormat()} - {stop.pickupCloseTime?.toUiTimeFormat()}
                                  </div>
                                )}
                                {stop.type === "SERVICE_CENTER" && <div className="xgs-route-summary__list__item__details__info">XGS Service Center</div>}
                              </div>
                            </div>
                            <div>
                              <div className="xgs-route-summary__list__item__status">
                                {stop.status === StopStatus.COMPLETED && <>Completed</>}
                                {stop.status === StopStatus.ACTIVE && <>Active</>}
                                {stop.status === StopStatus.UPCOMING && <>Upcoming</>}
                              </div>
                              <AddressMapButton
                                lat={stop.address.lat}
                                lng={stop.address.lng}
                              />
                            </div>
                          </div>
                          {((routeSummaryState.route?.stops?.length !== (index + 1)) || routeSummaryState.route?.destination) && <div className="xgs-route-summary__list__item__arrow"></div>}
                        </div>
                      </>
                    ))}
                  </>
                )}
                {!routeSummaryState.route.stops && (
                  <div className="xgs-route-summary__list__no-stops">
                    No assigned stops yet.
                  </div>
                )}
                {routeSummaryState.route.destination && (
                  <div className="xgs-route-summary__list__item xgs-route-summary__list__item--sc">
                    <div className="xgs-route-summary__list__item__box">
                      <div className="xgs-route-summary__list__item__info">
                        <div className="xgs-route-summary__list__item__number">
                          <XGSIcon
                            icon={XGSIcons.faWarehouse}
                            className="xgs-route-summary__list__item__icon"
                          />
                        </div>
                        <div className="xgs-route-summary__list__item__details">
                          <div className="xgs-route-summary__list__item__details__address">
                            {routeSummaryState.route.destination.address.address1}, {routeSummaryState.route.destination.address.city}, {routeSummaryState.route.destination.address.state}
                          </div>
                          <div className="xgs-route-summary__list__item__details__info">XGS Service Center</div>
                        </div>
                      </div>
                      <div className="xgs-route-summary__list__item__right-column">
                        <AddressMapButton
                          lat={routeSummaryState.route.destination.address.lat}
                          lng={routeSummaryState.route.destination.address.lng}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {!routeSummaryState.route.stops?.length && <NoRoute />}
        </>
      )}
      {routeSummaryState.requestFailed && routeSummaryState.requester === "SUMMARY" && (
        <XGSErrorMessage className="xgs-route-summary__failed">
          {routeSummaryState.requestFailReason === "Not found" ? "No probills for delivery" : routeSummaryState.requestFailReason || "Error. Please try again later."}
        </XGSErrorMessage>
      )}
    </div>
  );
}

export default RouteSummary;
