import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../app/route/RoutesConfig";
import "./menu.scss";

const Menu: React.FC<{}> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    //
  }, []);

  return (
    <div className="xgs-menu">
      <div
        className={`xgs-menu__item${location.pathname === ROUTES.home ? " xgs-menu__item--active" : ""}`}
        onClick={() => navigate(ROUTES.home)}
      >
        Summary
      </div>
      <div
        className={`xgs-menu__item${location.pathname === ROUTES.map ? " xgs-menu__item--active" : ""}`}
        onClick={() => navigate(ROUTES.map)}
      >
        Map
      </div>
    </div>
  );
};

export default Menu;
