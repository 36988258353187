import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import RouteMapService from "../../app/data/route-map/routeMapService";
import { initialRouteMapState } from "./RouteMapState";

const routeMapService = RouteMapService.getInstance();

export const routeMapSlice = createSlice({
  name: "routeMap",
  initialState: initialRouteMapState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestFailReason = null;
      state.requester = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailReason = payload;
    },
    setRouteMapData: (state, { payload }) => {
      state.mapData = payload;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setRouteMapData
} = routeMapSlice.actions;

export const routeMapSelector = (state: IState) => state.routeMap;

export const getRouteMapData = (
  onFailed?: (httpStatusCode: number) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("MAP"));
  const response = await routeMapService.getRouteMapData();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setRouteMapData(response.data));
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
    onFailed && onFailed(response.status);
  }
};

const routeMapReducer = routeMapSlice.reducer;
export default routeMapReducer;