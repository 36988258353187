import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface ExceptionModel {
  comment: string;
  id: string;
  itemId: string;
  level: string;
  manifest: number;
  photosCount: number;
  probills: number[];
  reason: string;
  refused: boolean;
}

export interface ShortExceptionModel {
  id: string;
  probills: number[];
  level: string;
}

export interface ExceptionFormModel {
  reason?: string;
  // refused: boolean | undefined;
  comment?: string;
}

export const ExceptionSchema: Yup.ObjectSchema<any> = Yup.object({
  reason: Yup.string().required(validationMessages.required),
  comment: Yup.string().matches(/\S+/, validationMessages.notValid).max(60, "The value must be no more than 60 characters").required(validationMessages.required)
}).defined();

export const ExceptionIncidentOptions = [
  { label: "Damaged freight", value: "3" },
  { label: "Misloaded freight", value: "M" },
  { label: "Overaged freight", value: "O" },
  { label: "Refused-72 hour", value: "Z" },
  { label: "Refused-on hand", value: "R" },
  { label: "Shortage freight", value: "S" },
  { label: "Undeliverable as is", value: "U" }
];
