import React from "react";
import { ProbillItem } from "../../../app/data/stop-details/models";
import ExceptionButton from "../../exceptions/exceptionButton";

export interface RollDetailsProps {
  stopNumber: number;
  rollDetails: ProbillItem[];
  probillHasException?: boolean;
}

export const RollDetails: React.FC<RollDetailsProps> = ({
  stopNumber,
  rollDetails,
  probillHasException,
}) => {
  return (
    <>
      {rollDetails.map((roll: ProbillItem, rollIdx) => {
        return (
          <div key={roll.identifier} className={`xgs-stop-details__roll`}>
            <span className={`xgs-stop-details__roll__id`}>
              {roll.identifier}
            </span>
            <span className="xgs-stop-details__roll__desc">
              {roll.description}
            </span>
            <span className="xgs-stop-details__roll__weight">
              {roll.weight + " lb"}
            </span>
            <span className="xgs-stop-details__roll__buttons">
              {(roll.identifier || roll.exception) &&
                <ExceptionButton
                  stopNumber={stopNumber}
                  probills={[roll.probill]}
                  itemId={roll.identifier}
                  source="ITEM"
                  exception={roll.exception}
                />
              }
            </span>
          </div>
        );
      })}
    </>
  );
};
