import api, { ApiResponse } from "../api";
import { SubmitPodRequest, SubmitPodResponse } from "./models";

class DeliverShipmentsService {
  private static instance: DeliverShipmentsService;
  private constructor() {};

  static getInstance(): DeliverShipmentsService {
    if (!DeliverShipmentsService.instance) {
      DeliverShipmentsService.instance = new DeliverShipmentsService();
    }
    return DeliverShipmentsService.instance;
  };

  public submitPod = async (request: SubmitPodRequest): Promise<ApiResponse<SubmitPodResponse>> => {
    return await api.post('/drivers/routes/agents/delivery', request);
  }
}

export default DeliverShipmentsService;
