import React from "react";
import { RollDetails } from "../roll-details/rollDetails";
import { ProbillDetails } from "../../../app/data/stop-details/models";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "../../../components/accordion/accordion";

export interface ProbillAccordionItemProps {
  stopNumber: number;
  probill: ProbillDetails;
  idx: number;
  consigneeHasException?: boolean;
}

export const ProbillAccordionItem: React.FC<ProbillAccordionItemProps> = ({
  stopNumber,
  probill,
  consigneeHasException
}) => {

  return (
    <AccordionItem className={`xgs-probill-accordion`}>
      <AccordionItemButton className={`xgs-probill-accordion__button`}>
        Probill: {probill.probill}
      </AccordionItemButton>
      <AccordionItemPanel className="xgs-probill-accordion__panel">
        <RollDetails
          stopNumber={stopNumber}
          rollDetails={probill.itemDetails}
          probillHasException={!!probill.exception || consigneeHasException}
        />
      </AccordionItemPanel>
    </AccordionItem>
  );
};
