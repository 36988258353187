import { useMemo, useState } from "react";
import "./stopDetails.scss";
import { ProbillAccordionItem } from "./probill-accordion/probillAccordionItem";
import { ConsigneeAccordionItem } from "./consignee-accordion/consigneeAccordionItem";
import "./consignee-accordion/consigneeAccordionItem.scss";
import "./probill-accordion/probillAccordionItem.scss";
import { useSelector } from "react-redux";
import { stopDetailsSelector } from "../../slices/stop-details/stopDetailsSlice";
import Loading from "../../components/loading/loading";
import { useParams } from "react-router-dom";
import { StopDetailsPath } from "./routes";
import { StopStatus } from "../../app/data/common/constants";
import {
  ConsigneeDetails,
  ProbillDetails
} from "../../app/data/stop-details/models";
import { Accordion } from "../../components/accordion/accordion";
import SlideInModal from "../../components/slide-in-modal/slideInModal";
import { SignShipment } from "./sign-shipment/signShipment";
import { StopDetailsHeader } from "./stop-details-header/stopDetailsHeader";
import { ShipperAccordionItem } from "./shipper-accordion/shipperAccordionItem";

interface PodModalState {
  show: boolean;
  consignee: ConsigneeDetails | null;
}

const initialPodModalState: PodModalState = {
  show: false,
  consignee: null,
};

export const StopDetails = () => {
  const stopDetailsState = useSelector(stopDetailsSelector);
  const [podModal, setPodModal] = useState<PodModalState>(initialPodModalState);
  const params = useParams();
  const stopNumber = useMemo(() => {
    const stopParam = params[StopDetailsPath.stopNumber];
    if (stopParam) return parseInt(stopParam, 10);
    return null;
  }, [params]);
  const stopNumberIndex = stopDetailsState.details ? stopDetailsState.details.findIndex((stop) => stop.order === stopNumber) : -1;
  const stopDetailsData = stopDetailsState.details ? stopDetailsState.details[stopNumberIndex] : null;

  const onOpenPodModal = (consignee: ConsigneeDetails) => {
    setPodModal({
      show: true,
      consignee: consignee,
    });
  };

  const onClosePodModal = () => {
    setPodModal(initialPodModalState);
  };

  const stopAddress = useMemo(() => {
    if (!stopDetailsData) return "";
    return `${stopDetailsData.address.address1},
      ${stopDetailsData.address.city}, ${stopDetailsData.address.state}
    `;
  }, [stopDetailsData]);


  const isStopCompleted = stopDetailsData?.status === StopStatus.COMPLETED;

  return (
    <div className="xgs-stop-details">
      <SlideInModal
        title={podModal.consignee ? podModal.consignee.name : ""}
        show={podModal.show}
        onClose={onClosePodModal}
      >
        {podModal.consignee && (
          <SignShipment
            show={podModal.show}
            consignee={podModal.consignee}
            onCloseModal={onClosePodModal}
          />
        )}
      </SlideInModal>
      <Loading
        isLoading={stopDetailsState.requestStarted}
        className="xgs-route-summary__loading"
      />
      {stopDetailsData && (
        <>
          <StopDetailsHeader
            address={stopAddress}
            consigneesCount={stopDetailsData.consignees?.length || 1}
            probillsCount={stopDetailsData.probillsCount}
            itemsCount={stopDetailsData.itemsCount}
          />
            {stopDetailsData.type === "DELIVERY" && <Accordion openByDefault id="consignee-accordion">
              {stopDetailsData.consignees.map(
                (consignee, consigneeIdx) => (
                  <ConsigneeAccordionItem
                    stopNumber={stopNumber || 0}
                    onClickSign={onOpenPodModal}
                    consignee={consignee}
                    id={`consignee-accordion-item-${consigneeIdx}`}
                    key={`consignee-accordion-item-${consigneeIdx}`}
                    isStopCompleted={isStopCompleted}
                  >
                    <Accordion
                      id={`probill-accordion-${consigneeIdx}`}
                      openByDefault
                    >
                      {consignee.probills.map(
                        (probill: ProbillDetails, probillIdx) => (
                          <ProbillAccordionItem
                            stopNumber={stopNumber || 0}
                            idx={probillIdx}
                            probill={probill}
                            key={probill.probill}
                            consigneeHasException={!!consignee.exception}
                          />
                        )
                      )}
                    </Accordion>
                  </ConsigneeAccordionItem>
                )
              )}
            </Accordion>}
            {stopDetailsData.type === "PICKUP" && <Accordion openByDefault>
              <ShipperAccordionItem
                name={stopDetailsData.consignees[0].name}
                probills={stopDetailsData.consignees[0].probills}
                stopNumber={stopNumber || 0}
                completed={stopDetailsData.status === "COMPLETED"}
              >
                <Accordion openByDefault>
                  {stopDetailsData.consignees[0].probills.map((probill, idx) => {
                    return <ProbillAccordionItem
                      stopNumber={stopNumber || 0}
                      idx={idx}
                      probill={probill}
                      key={probill.probill}
                    />
                  })}
                </Accordion>
              </ShipperAccordionItem>

            </Accordion>}
        </>
      )}
      {!stopDetailsData && (
        <div className="xgs-stop-details__no-stop">Stop not found</div>
      )}
    </div>
  );
};
