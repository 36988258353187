import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/storeHooks";
import PageLoading from "../page-loading/pageLoading";
import {
  getRouteSummary,
  routeSummarySelector
} from "../../slices/route-summary/routeSummarySlice";
import {
  getRouteMapData,
  routeMapSelector
} from "../../slices/route-map/routeMapSlice";
import {
  getAllStopsDetails,
  stopDetailsSelector
} from "../../slices/stop-details/stopDetailsSlice";

const DataLoader: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const routeMapState = useSelector(routeMapSelector);
  const routeSummaryState = useSelector(routeSummarySelector);
  const stopDetailsState = useSelector(stopDetailsSelector);

  useEffect(() => {
    dispatch(getRouteSummary());
    dispatch(getAllStopsDetails());
    dispatch(getRouteMapData());
  }, [dispatch]);

  const isLoading = routeMapState.requestStarted || routeSummaryState.requestStarted || stopDetailsState.requestStarted;

  const getMessage = () => (
    <>
      <div>{routeSummaryState.requestStarted && "Loading Route..."}</div>
      <div>{stopDetailsState.requestStarted && "Loading Stops..."}</div>
      <div>{routeMapState.requestStarted && "Loading Map..."}</div>
    </>
  );

  useEffect(() => {
    if (isLoading) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isLoading]);

  return isLoading
    ? <PageLoading
        isLoading={true}
        message={getMessage()}
      />
    : null;
};

export default DataLoader;
