import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import RouteSummaryService from "../../app/data/route-summary/routeSummaryService";
import { initialRouteSummaryState } from "./RouteSummaryState";

const routeSummaryService = RouteSummaryService.getInstance();

export const routeSummarySlice = createSlice({
  name: "routeSummary",
  initialState: initialRouteSummaryState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestFailReason = null;
      state.requester = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailReason = payload;
    },
    setRouteSummary: (state, { payload }) => {
      state.route = payload;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setRouteSummary
} = routeSummarySlice.actions;

export const routeSummarySelector = (state: IState) => state.routeSummary;

export const getRouteSummary = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SUMMARY"));
  const response = await routeSummaryService.getRouteSummary();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setRouteSummary(response.data));
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
  }
};

const routeSummaryReducer = routeSummarySlice.reducer;
export default routeSummaryReducer;
