import AuthCallbackReceiver from "../../features/auth/callbackReceiver";
import RouteMap from "../../features/route-map/routeMap"
import RouteSummary from "../../features/route-summary/routeSummary";
import UserProfile from "../data/user/UserProfile";
import { StopDetails } from "../../features/stop-details/stopDetails";
import { StopDetailsPath } from "../../features/stop-details/routes";

export const ROUTES = {
  authCallback: "/auth-callback",
  home: "/",
  map: "/map",
  stopDetails: "stop-details"
};

export interface RoutesComponent {
  path: string;
  component: React.FC<any>;
  fixedPath?: string;
  isPrivate?: boolean;
}

export const publicRoutesComponents: RoutesComponent[] = [
  {
    path: ROUTES.authCallback,
    component: AuthCallbackReceiver
  }
];

const driverRoutesComponents: RoutesComponent[] = [
  {
    path: ROUTES.map,
    component: RouteMap,
    isPrivate: true
  },
  {
    path: ROUTES.home,
    component: RouteSummary,
    isPrivate: true
  },
  {
    path: `${ROUTES.stopDetails}/:${StopDetailsPath.stopNumber}`,
    component: StopDetails,
    isPrivate: true
  }
];

export const getRouteConfig = (
  user: UserProfile | null | undefined
) => {
  if (!user) return [];
  return driverRoutesComponents;
};
