import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks/storeHooks";
import DropdownMenu, { DropdownMenuItem } from "../../../components/dropdown-menu/dropdownMenu";
import { userSelector } from "../../../slices/user/userSlice";
import { logout } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import XGSIcon from "../../../components/icon/xgsIcon";
import XGSIcons from "../../../components/icon/xgsIcons";
import "./userMenu.scss";

const UserMenu: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const userState: UserState = useSelector(userSelector);
  const [opened, setOpened] = useState<boolean>(false);

  const menuItems: DropdownMenuItem[] = [
    {
      text: "Log out",
      onClick: () => {
        setTimeout(() => {
          dispatch(logout());
        }, 500);
      },
      icon: XGSIcons.faSignOutAlt
    }
  ];

  const openDropdown = () => {
    setOpened(true);
  };

  const closeDropdown = () => {
    setOpened(false);
  };

  return (
    <div>
      <div className="xgs-user-menu" onClick={openDropdown}>
        <div className="xgs-user-menu__user xgs-user-menu__user--normal">
          <div className="xgs-user-menu__user__name">
            {userState.profile?.firstName ? `${userState.profile?.firstName} ${userState.profile.lastName}` : userState.profile?.name} 
          </div>
        </div>
        <div className="xgs-user-menu__user xgs-user-menu__user--short">
          {userState.profile?.firstName ? userState.profile?.firstName.slice(0, 1) : userState.profile?.name.slice(0, 1)}
        </div>
        <span className="xgs-user-menu__arrow">
          <XGSIcon icon={opened ? XGSIcons.faChevronUp : XGSIcons.faChevronDown} />
        </span>
      </div>
      <DropdownMenu
        menuItems={menuItems}
        opened={opened}
        onClose={closeDropdown}
      />
    </div>
  );
}

export default UserMenu;